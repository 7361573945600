import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminStaffManagement from "./admin/views/AdminStaffManagement";
const AppUsersRouter = () => {
  return (
    <Routes>
      <Route path="" element={<AdminStaffManagement />} />
    </Routes>
  );
};

export default AppUsersRouter;
