import React, {useEffect, useState} from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import AppLoader from "../../../learning/user/components/AppLoader"
import { fetchPDFfromS3 } from '../../../learning/user/services/api.services';
import './AppPdfViewer.scss';
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const AppPdfViewer = ({subjectfileUrl}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null)
  const [loader, setLoader] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth())
  const [pdfWidth, setPdfWidth] = useState(0)

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }
  useEffect(()=>{
    const handlePDFfroms3 = async(subjectfileUrl) => {
      await fetchPDFfromS3(subjectfileUrl)
      .then((resp) => {
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        setPdfUrl(window.URL.createObjectURL(pdfBlob));
        setLoader(true);
      })
      .catch((err) => {
          setLoader(false);
      });
    }
    handlePDFfroms3(subjectfileUrl);
    updatePdfFrameWidth(deviceWidth);
  }, [])

  useEffect(() => {
    const updateDeviceWidth = () => {
      const screenWidth = getCurrentScreenWidth()
      setDeviceWidth(screenWidth)
      updatePdfFrameWidth(screenWidth);
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);


  const updatePdfFrameWidth = (screenWidth) => {
    var pdfFrameWidth = 0
    if(screenWidth > 800 && screenWidth < 1200){
      pdfFrameWidth = screenWidth - ((screenWidth * 50) / 100);
    }
    else if(screenWidth > 500 && screenWidth <= 800){
      pdfFrameWidth = screenWidth - ((screenWidth * 30) / 100);
    }
    else if(screenWidth <= 500){
      pdfFrameWidth = screenWidth - ((screenWidth * 15) / 100);
    }
    else{
      pdfFrameWidth = screenWidth - ((screenWidth * 45) / 100);
    }
    setPdfWidth(pdfFrameWidth);
  }
 

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const changePage = (offset)=>{
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = ()=>{
    changePage(-1);
  }

  const nextPage = ()=>{
    changePage(1);
  }
  const maxWidth = pdfWidth

  return (
    <div className='app-pdf-viewer' style={{width: "100%"}}>
      {!loader ? 
        <div className='app-pdf-viewer-loader'>
          <AppLoader />
        </div>
      : 
        pdfUrl && 
          <>
            <Document file={pdfUrl+'#toolbar=0'} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} width={maxWidth}/>
            </Document>
            <div className='app-pdf-viewer-controls'>
              
              <button className='app-pdf-viewer-controls-left'
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
              <FaAngleLeft />
              </button>
              <span>{pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</span>
              <button className='app-pdf-viewer-controls-right'
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                <FaAngleRight />
              </button>
            </div>
          </>
      }
    </div>
  )
}

export default AppPdfViewer