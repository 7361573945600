import { createContext, useReducer, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import ResetPassword from "./learning/user/views/ResetPassword";
import CourseSelector from "./learning/user/views/CourseSelector";
import Login from "./learning/user/views/Login";
import { Toaster } from "react-hot-toast";
import Dashboard from "./learning/user/views/Dashboard";
import LearningAdminDashboard from "./learning/admin/views/AdminDashboard";
import PricingPlanSelector from "./learning/admin/views/PricingPlanSelector";
import StripeSetupSession from "./learning/user/components/StripeSetupSession";
import StripeSetupSuccessPage from "./learning/admin/views/StripeSetupSuccessPage";
import AppLearningRouter from "./learning/AppLearningRouter";
import AppLearningOutlet from "./learning/AppLearningOutlet";
import AppComplianceOutlet from "./compliance/AppComplianceOutlet";
import ComplianceAdminDashboard from "./compliance/admin/views/ComplianceAdmin";
import PolicyAdminDashboard from "./policy/admin/views/PolicyAdmin";
import AppPolicyOutlet from "./policy/AppPolicyOutlet";
import IncidentAdminDashboard from "./incident/admin/views/IncidentAdmin";
import AppIncidentOutlet from "./incident/AppIncidentOutlet";
import RiskAdminDashboard from "./risk/admin/views/RiskAdmin";
import AppRiskOutlet from "./risk/AppRiskOutlet";
import UsersAdminDashboard from "./users/admin/views/UsersAdmin";
import AppUsersOutlet from "./users/AppUsersOutlet";
import SurveyForm from "./compliance/user/views/SurveyForm";
import { DashboardProvider } from "./learning/user/contexts/DashboardContext";
import EmailSubscription from "./marketing/EmailSubscription";
import PolicyUser from "./policy/user/views/PolicyUser";
import AccountSettings from "./common/views/AccountSettings";
import UserSettings from "./common/views/UserSettings";

export const AuthContext = createContext();
sessionStorage.clear();
// localStorage.clear();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  is_admin: false,
  is_organisation_pricing_plan: false,
  currentApp: null,
  currentUserType: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("is_admin", action.payload.is_admin);
      localStorage.setItem(
        "is_organisation_pricing_plan",
        action.payload.is_organisation_pricing_plan
      );
      localStorage.setItem("currentApp", action.payload.currentApp);
      localStorage.setItem("currentUserType", action.payload.currentUserType);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        is_admin: action.payload.is_admin,
        is_organisation_pricing_plan:
          action.payload.is_organisation_pricing_plan,
        currentApp: action.payload.currentApp,
        currentUserType: action.payload.currentUserType,
      };
    case "SET_CURRENT_APP":
      localStorage.setItem("currentApp", action.payload);
      return { ...state, currentApp: action.payload };
    case "TOGGLE_USER_TYPE":
      const newType = state.currentUserType === "admin" ? "user" : "admin";
      localStorage.setItem("currentUserType", newType);
      return { ...state, currentUserType: newType };
    case "UPDATE_PRICING_PLAN":
      localStorage.setItem(
        "is_organisation_pricing_plan",
        action.payload.is_organisation_pricing_plan
      );
      return {
        ...state,
        is_organisation_pricing_plan:
          action.payload.is_organisation_pricing_plan,
      };
    case "LOGOUT":
      localStorage.clear();
      window.location.reload();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        is_admin: false,
        is_organisation_pricing_plan: false,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  // this below code is used to control the zoom rate of the app
  // useEffect(() => {
  //   document.body.style.zoom = "90%";
  // }, []);
  if (localStorage.getItem("token")) {
    state.isAuthenticated = true;
    state.token = localStorage.getItem("token");
    state.is_admin = JSON.parse(localStorage.getItem("is_admin"));
    state.is_organisation_pricing_plan = JSON.parse(
      localStorage.getItem("is_organisation_pricing_plan")
    );
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <div className="app">
        <Toaster />
        <Router>
          <Routes>
            {state.isAuthenticated ? (
              <>
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route path="/user-settings" element={<UserSettings />} />
                {state.is_admin ? (
                  state.is_organisation_pricing_plan ? (
                    <>
                      <Route
                        path="/"
                        element={
                          <DashboardProvider>
                            <CourseSelector />
                          </DashboardProvider>
                        }
                      />
                      <Route path="/users" element={<AppUsersOutlet />}>
                        <Route
                          path="admin/*"
                          element={<UsersAdminDashboard />}
                        />
                      </Route>
                      <Route path="/learning" element={<AppLearningOutlet />}>
                        <Route
                          path="admin/*"
                          element={<LearningAdminDashboard />}
                        />
                      </Route>
                      <Route
                        path="/compliance"
                        element={<AppComplianceOutlet />}
                      >
                        <Route
                          path="admin/*"
                          element={<ComplianceAdminDashboard />}
                        />
                      </Route>
                      <Route path="/policy" element={<AppPolicyOutlet />}>
                        <Route
                          path="admin/*"
                          element={<PolicyAdminDashboard />}
                        />
                        <Route
                          path="user/*"
                          element={<PolicyUser isAdmin={true} />}
                        />
                      </Route>
                      <Route path="/risk" element={<AppRiskOutlet />}>
                        <Route
                          path="admin/*"
                          element={<RiskAdminDashboard />}
                        />
                      </Route>
                      <Route path="/incident" element={<AppIncidentOutlet />}>
                        <Route
                          path="admin/*"
                          element={<IncidentAdminDashboard />}
                        />
                      </Route>
                      <Route
                        path="/:course/*"
                        element={
                          <DashboardProvider>
                            <Dashboard />
                          </DashboardProvider>
                        }
                      />
                      <Route
                        path="/reset-password/:token"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="/stripe_gateway_success/*"
                        element={<StripeSetupSuccessPage />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to="/learning/admin/*" />}
                      />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Login />} />
                      <Route
                        path="/pricing_plan_selector"
                        element={<PricingPlanSelector />}
                      />
                      <Route
                        path="/stripe_gateway"
                        element={<StripeSetupSession />}
                      />
                      <Route
                        path="/stripe_gateway_success/*"
                        element={<StripeSetupSuccessPage />}
                      />
                      <Route
                        path="/reset-password/:token"
                        element={<ResetPassword />}
                      />
                      <Route path="*" element={<Navigate to="/" />} />
                    </>
                  )
                ) : (
                  <>
                    <Route
                      path="/"
                      element={
                        <DashboardProvider>
                          <CourseSelector />
                        </DashboardProvider>
                      }
                    />
                    <Route
                      path="/:course/*"
                      element={
                        <DashboardProvider>
                          <Dashboard />
                        </DashboardProvider>
                      }
                    />
                    <Route
                      path="policy/user/*"
                      element={<PolicyUser isAdmin={true} />}
                    />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPassword />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                  </>
                )}
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/survey-form/:surveyCode" element={<SurveyForm />} />
            <Route path="/subscription" element={<EmailSubscription />} />
          </Routes>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
