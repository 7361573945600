import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonSidebar from "../components/CommonSidebar";
import {
  FaArrowLeft,
  FaEdit,
  FaCameraRetro,
  FaPlusCircle,
  FaMinusCircle,
  FaRegTrashAlt,
  FaPencilAlt,
  FaRegCreditCard,
  FaRegTimesCircle,
  FaRegCheckCircle,
  FaBars,
} from "react-icons/fa";
import PlanCard from "../components/PlanCard";
import "./UserSettings.scss";
import user_avatar from "../../learning/images/icons/user_avatar.jpg";
import settingIcon from "../images/gear.png";
import logoutIcon from "../images/logout.png";
import {
  getJobroles,
  createJobrole,
  removeJobrole,
} from "../services/api.services";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import AppLoader from "../../learning/user/components/AppLoader";
import Organisation from "../components/Organisation";

const UserSettings = () => {
  const { state } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const navigate = useNavigate();

  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Settings",
      icon: settingIcon,
      onClick: () => navigate("/user-settings"),
    },
  ]);
  const [loader, setLoader] = useState(true);

  const [jobroleData, setJobroleData] = useState([]);
  const [isAddingJobRole, setIsAddingJobRole] = useState(false);
  const [newJobRole, setNewJobRole] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);

  const [editedJobRole, setEditedJobRole] = useState("");
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }
  const adminRoutes = {
    users: "/users/admin/",
    learning: "/learning/admin/",
    risk: "/risk/admin/",
    policy: "/policy/admin/",
    incident: "/incident/admin/",
    compliance: "/compliance/admin/",
  };

  const userRoutes = {
    users: "/users/",
    learning: "/",
    risk: "/",
    policy: "/policy/user/",
    incident: "/",
    compliance: "/",
  };

  const handleNavigate = () => {
    const currentApp = localStorage.getItem("currentApp");
    const currentUserType = localStorage.getItem("currentUserType");
    const route =
      currentUserType === "admin"
        ? adminRoutes[currentApp]
        : userRoutes[currentApp];
    navigate(route);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(false);
      try {
        const jobrolesResponse = await getJobroles(header);
        setJobroleData(jobrolesResponse.data.results);
        setLoader(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };

    fetchData();
  }, []);

  // Jobrole Methods
  const handleAddJobRole = async () => {
    if (!newJobRole.trim()) {
      toast.error("Job role cannot be empty.");
      return;
    }

    try {
      const response = await createJobrole(header, newJobRole);

      if (response.status === 200 || response.status === 201) {
        toast.success(`Added job role: ${newJobRole}`);
        setJobroleData([...jobroleData, { name: newJobRole }]);
        setNewJobRole("");
        setIsAddingJobRole(false);
      } else {
        toast.error(`Failed to add job role: ${response.message}`);
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
    }
  };

  const handleRemoveJobRole = async (jobroleId) => {
    try {
      const response = await removeJobrole(header, jobroleId);

      if (response.status === 200 || response.status === 204) {
        const updatedData = jobroleData.filter((item) => item.id !== jobroleId);
        setJobroleData(updatedData);
        toast.success("Deleted successfully");
      } else {
        toast.error("Failed to delete job role.");
      }
    } catch (error) {
      toast.error("An unexpected error occurred while deleting the job role.");
    }
  };

  // Handler for saving an edited job role
  const handleEditJobRole = (index) => {
    toast.success(`Updated job role: ${editedJobRole}`);
    const updatedData = [...jobroleData];
    updatedData[index].name = editedJobRole;
    setJobroleData(updatedData);
    setEditingIndex(null);
    setEditedJobRole("");
  };

  return (
    <div className="user-settings">
      <div className="user-settings-top">
        <FaBars onClick={() => setOpenMobileNav(true)} />
      </div>
      <div className="user-settings-body">
        <div className="user-settings-body-sidebar">
          <CommonSidebar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          ></CommonSidebar>
        </div>
        <div className="user-settings-body-content">
          <div className="user-settings-body-content-elements">
            <div className="user-settings-body-content-elements-top">
              <div
                className="user-settings-body-content-elements-top-icon"
                onClick={() => handleNavigate()}
              >
                <FaArrowLeft />
              </div>
              <div className="user-settings-body-content-elements-top-title">
                <div className="user-settings-body-content-elements-top-title-text">
                  Settings
                </div>
              </div>
            </div>
            <div className="user-settings-body-content-elements-body">
              <Organisation header={header} />
              <hr
                style={{
                  width: "100%",
                  margin: "20px auto",
                  border: "none",
                  borderTop: `1px solid lightgray`,
                }}
              />
              {/* <div className="user-settings-body-content-elements-body-plan_info">
                <div className="user-settings-body-content-elements-body-plan_info-title">
                  Plan Info <FaEdit />
                </div>
                <div className="user-settings-body-content-elements-body-plan_info-sub_title">
                  Change Plan
                </div>
                <div className="user-settings-body-content-elements-body-plan_info-content">
                  <PlanCard /> <PlanCard isSelectedPlan={false} />
                </div>
                <div className="user-settings-body-content-elements-body-plan_info-sub_title">
                  Payment Method
                </div>
                <div className="user-settings-body-content-elements-body-plan_info-content">
                  <div className="user-settings-body-content-elements-body-plan_info-content-card">
                    <div className="user-settings-body-content-elements-body-plan_info-content-card-top">
                      Credit Card <FaRegCreditCard />
                    </div>
                    <div className="user-settings-body-content-elements-body-plan_info-content-card-data">
                      **** **** **** 5874
                    </div>
                    <div className="user-settings-body-content-elements-body-plan_info-content-card-bottom">
                      <FaMinusCircle />
                    </div>
                  </div>

                  <div className="user-settings-body-content-elements-body-plan_info-content-add_card">
                    <FaPlusCircle />
                  </div>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  margin: "20px auto",
                  border: "none",
                  borderTop: `1px solid lightgray`,
                }}
              /> */}
              <div className="user-settings-body-content-elements-body-jobrole">
                <div className="user-settings-body-content-elements-body-jobrole-title">
                  Jobrole <FaPencilAlt />
                </div>
                <div className="user-settings-body-content-elements-body-jobrole-content">
                  {/* Add Job Role Section */}
                  {isAddingJobRole ? (
                    <div className="user-settings-body-content-elements-body-jobrole-content-add-input">
                      <input
                        type="text"
                        value={newJobRole}
                        onChange={(e) => setNewJobRole(e.target.value)}
                        placeholder="Enter job role"
                      />
                      <div className="user-settings-body-content-elements-body-jobrole-content-add-input-icons">
                        <FaRegCheckCircle
                          style={{ color: "green" }}
                          onClick={handleAddJobRole}
                        />
                        <FaRegTimesCircle
                          style={{ color: "red" }}
                          onClick={() => setIsAddingJobRole(false)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="user-settings-body-content-elements-body-jobrole-content-add_button"
                      onClick={() => setIsAddingJobRole(true)}
                    >
                      <FaPlusCircle />
                      Add Jobrole
                    </div>
                  )}

                  {/* Job Role Items */}
                  <div className="user-settings-body-content-elements-body-jobrole-content-jobroles_container">
                    {jobroleData.map((item, index) => (
                      <div
                        key={index}
                        className="user-settings-body-content-elements-body-jobrole-content-jobroles_container-item"
                      >
                        {editingIndex === index ? (
                          <div className="user-settings-body-content-elements-body-jobrole-content-jobroles_container-item-edit-input">
                            <input
                              type="text"
                              value={editedJobRole}
                              onChange={(e) => setEditedJobRole(e.target.value)}
                              placeholder="Edit job role"
                            />
                            <div className="user-settings-body-content-elements-body-jobrole-content-jobroles_container-item-edit-input-icons">
                              <FaRegCheckCircle
                                style={{ color: "green" }}
                                onClick={() => handleEditJobRole(index)}
                              />
                              <FaRegTimesCircle
                                style={{ color: "red" }}
                                onClick={() => setEditingIndex(null)}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="user-settings-body-content-elements-body-jobrole-content-jobroles_container-item-text">
                              {item.name}
                            </div>
                            <div className="user-settings-body-content-elements-body-jobrole-content-jobroles_container-item-icons">
                              <FaPencilAlt
                                onClick={() => {
                                  setEditingIndex(index);
                                  setEditedJobRole(item.name);
                                }}
                              />
                              <FaRegTrashAlt
                                onClick={() => handleRemoveJobRole(item.id)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
