import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaEdit, FaEye, FaEyeSlash, FaBars } from "react-icons/fa";
import CommonSidebar from "../components/CommonSidebar";
import AppLoader from "../../learning/user/components/AppLoader";
import "./AccountSettings.scss";
import userAvatar from "../../learning/images/icons/user_avatar.jpg";
import accountIcon from "../images/user.png";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import {
  updateUserPassword,
  getUserInfo,
  updateUserDetails,
} from "../services/api.services";
import EditProfilePicturePopup from "../components/EditProfilePicturePopup";
import PersonalInfo from "../components/PersonalInfo";

const AccountSettings = () => {
  const { state, dispatch } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());

  const [loader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState();

  const [showContent, setShowContent] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [navbarData, setNavbarData] = useState([
    {
      name: "Account",
      icon: accountIcon,
      onClick: () => navigate("/account-settings"),
    },
  ]);
  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }
  const toggleContent = () => {
    setShowContent((prev) => !prev);
    setErrorMessage("");
    clearFields();
  };

  const clearFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleChangePassword = async () => {
    // Basic validation
    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (newPassword.length < 8) {
      toast.error("New password must be at least 8 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirmation do not match.");
      return;
    }

    try {
      const res = await updateUserPassword(
        header,
        currentPassword,
        newPassword
      );

      // Check if the response indicates success
      if (res.data.success) {
        toast.success(res.data.message || "Password changed successfully");
        toggleContent();
      } else {
        setErrorMessage(res.data.message || "Error changing password.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  const navigate = useNavigate();

  const adminRoutes = {
    users: "/users/admin/",
    learning: "/learning/admin/",
    risk: "/risk/admin/",
    policy: "/policy/admin/",
    incident: "/incident/admin/",
    compliance: "/compliance/admin/",
  };

  const userRoutes = {
    users: "/users/",
    learning: "/",
    risk: "/",
    policy: "/policy/user/",
    incident: "/",
    compliance: "/",
  };

  const handleNavigate = () => {
    const currentApp = localStorage.getItem("currentApp");
    const currentUserType = localStorage.getItem("currentUserType");
    const route =
      currentUserType === "admin"
        ? adminRoutes[currentApp]
        : userRoutes[currentApp];
    navigate(route);
  };

  return (
    <div className="account-settings">
      <div className="account-settings-top">
        <FaBars onClick={() => setOpenMobileNav(true)} />
      </div>

      <div className="account-settings-body">
        <div className="account-settings-body-sidebar">
          <CommonSidebar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          ></CommonSidebar>
        </div>
        <div className="account-settings-body-content">
          {/* <div className="account-settings-body-content-header">
              <AppHeader toggleSidebar={toggleSidebar} />
            </div> */}
          <div className="account-settings-body-content-elements">
            <div className="account-settings-body-content-elements-top">
              <div
                className="account-settings-body-content-elements-top-icon"
                onClick={() => handleNavigate()}
              >
                <FaArrowLeft />
              </div>
              <div className="account-settings-body-content-elements-top-title">
                <div className="account-settings-body-content-elements-top-title-text">
                  My Account
                </div>
              </div>
            </div>
            <div className="account-settings-body-content-elements-body">
              {!loader ? (
                <AppLoader style={{ height: "300px" }} />
              ) : (
                <PersonalInfo header={header} />
              )}
              <hr
                style={{
                  width: "100%",
                  margin: "20px auto",
                  border: "none",
                  borderTop: `1px solid lightgray`,
                }}
              />
              <div className="account-settings-body-content-elements-body-change_password">
                <div
                  className="account-settings-body-content-elements-body-change_password-title"
                  onClick={toggleContent}
                  style={{ cursor: "pointer" }}
                >
                  Change Password <FaEdit />
                </div>

                {showContent && (
                  <div className="account-settings-body-content-elements-body-change_password-content">
                    <div className="account-settings-body-content-elements-body-change_password-content-items">
                      {/* Current Password */}
                      <div className="account-settings-body-content-elements-body-change_password-content-items-row">
                        <div className="account-settings-body-content-elements-body-change_password-content-items-row-item">
                          <label className="label">Current Password</label>
                          <div className="input-container">
                            <input
                              type={showCurrentPassword ? "text" : "password"}
                              value={currentPassword}
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                              className="input"
                            />
                            <span
                              onClick={() =>
                                setShowCurrentPassword((prev) => !prev)
                              }
                              className="eye-icon"
                            >
                              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                        </div>

                        {/* New Password */}
                        <div className="account-settings-body-content-elements-body-change_password-content-items-row-item">
                          <label className="label">New Password</label>
                          <div className="input-container">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              className="input"
                            />
                            <span
                              onClick={() =>
                                setShowNewPassword((prev) => !prev)
                              }
                              className="eye-icon"
                            >
                              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Confirm New Password */}
                      <div
                        className="account-settings-body-content-elements-body-change_password-content-items-row"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div className="account-settings-body-content-elements-body-change_password-content-items-row-item">
                          <label className="label">Confirm New Password</label>
                          <div className="input-container">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              className="input"
                            />
                            <span
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              className="eye-icon"
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Error Message */}
                      {errorMessage && (
                        <div
                          className="error-message"
                          style={{ color: "red", marginBottom: "10px" }}
                        >
                          {errorMessage}
                        </div>
                      )}

                      {/* Buttons */}
                      <div className="account-settings-body-content-elements-body-change_password-content-items-bottom">
                        <button
                          className="secondary-button"
                          onClick={toggleContent}
                        >
                          Cancel
                        </button>
                        <button
                          className="primary-button"
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
