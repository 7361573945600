import React, { useState } from "react";
import toast from "react-hot-toast";
import "./EditProfilePicturePopup.scss";
import { FaRegImages, FaCamera } from "react-icons/fa";

const EditProfilePicturePopup = ({
  defaultText,
  userAvatar,
  updateFunction,
  header,
  instanceId,
  key,
  setKey,
  formDataKey = "avatar",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");
  const togglePopup = () => {
    setIsOpen(!isOpen);
    setError("");
  };
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
    }
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError("Please select an image file.");
      toast.error("Please select an image file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append(formDataKey, selectedFile);
      togglePopup();
      toast.loading("Updating Image..");
      const response = await updateFunction(header, instanceId, formData);
      if (response.status === 200) {
        setKey(key + 1);
        toast.remove();
        togglePopup();
        setSelectedFile(null);
        toast.success("Image updated successfully.");
        // onAvatarUpdate(response.data.dataInfo.avatar);
      } else {
        toast.remove();
        setError("Please select an image file.");
        toast.error("Please select an image file.");
      }
    } catch (error) {
      setError("Error uploading avatar:", error);
      toast.error("Error uploading avatar.");
    }
  };

  const cancelEdit = () => {
    setSelectedFile({});
    setError("");
    togglePopup();
  };

  return (
    <>
      <div className="edit-profile-picture-popup-icon" onClick={togglePopup}>
        <FaCamera />
      </div>

      {isOpen && (
        <div className="edit-profile-picture-popup-overlay popup-open">
          <div className="edit-profile-picture-popup-body popup-open">
            <div className="edit-profile-picture-popup-body-title">
              Edit Profile Picture
            </div>
            <div className="edit-profile-picture-popup-body-picture">
              {selectedImage ? (
                <img src={selectedImage} alt="Selected" />
              ) : userAvatar ? (
                <img src={userAvatar} alt="profile_picture" />
              ) : (
                <div className="placeholder"> {defaultText}</div>
              )}
            </div>
            <input type="file" name="avatar" onChange={handleFileChange} />
            {error && (
              <div className="edit-profile-picture-popup-error">{error}</div>
            )}
            <button
              className="edit-profile-picture-popup-body-submit"
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              className="edit-profile-picture-popup-body-close"
              onClick={cancelEdit}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfilePicturePopup;
