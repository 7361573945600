import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const clearLocalStorage = (error) => {
  if(error.response.status === 401) {
    localStorage.clear()
  }
}

export const createUser = async (payload) => {
  try {
    const res = await instance.post("api/user/create/", payload);
    return res;
  } catch (err) {
    return {
      status: "error",
      data: err.response.data,
      statusCode: err.response.status,
    };
  }
};

export const getUserDetail = async (payload) => {
  try {
    // const res = await http.get(`/me`);
    const res = await instance.post("api/user/login/", payload);
    return res;
  } catch (err) {
    return {
      status: "error",
      data: err.response.data,
      statusCode: err.response.status,
    };
  }
};

export const getUserCourseData = async (header) => {
  try {
    const res = await instance.get("api/user/info/", { headers: header });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const getUserCourses = async (header, pageNo = 1, recordsPerPage = 5, status = null) => {
  try {
    const url = status
      ? `api/learning-app/user/user-course/?page=${pageNo}&page_size=${recordsPerPage}&status=${status}`
      : `api/learning-app/user/user-course/?page=${pageNo}&page_size=${recordsPerPage}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
export const getUserCourseNames = async (header, status = null) => {
  try {
    const url = status
      ? `api/learning-app/user/user-course/course-names/?status=${status}`
      : "api/learning-app/user/user-course/course-names/";
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchUserCourses = async (header, searchKey, status = null) => {
  try {
    const url = status
      ? `api/learning-app/user/user-course/?status=${status}&search=${searchKey}`
      : `api/learning-app/user/user-course/?search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const setStartCourse = async (header, courseId) => {
  try {
    const res = await instance.post(
      "api/learning-app/user/user-course/start-course/",
      {
        course_id : courseId,
      },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateUserDetails = async (header, editedUserDetails) => {
  try {
    const res = await instance.put("api/user/info/", editedUserDetails, {
      headers: header,
    });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const getCourseDetails = async (header, id) => {
  try {
    const res = await instance.get("api/learning-app/user/courses/" + id + "/", {
      headers: header,
    });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await instance.post("api/user/forgot-password/", { email });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const res = await instance.post(`api/user/reset-password/${token}/`, {
      password: newPassword,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getCourseChapter = async (header, id) => {
  try {
    const res = await instance.get("api/learning-app/user/chapter/" + id + "/", {
      headers: header,
    });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const getCourseModule = async (header, id) => {
  try {
    const res = await instance.get("api/learning-app/user/module/" + id + "/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getDashboardData = async (header, courseId) => {
  try {
    const res = await instance.get("api/learning-app/user/dashboard/" + courseId + "/", {
      headers: header,
    });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const getAnnouncementsData = async (header, courseId) => {
  try {
    const res = await instance.get(
      "api/learning-app/user/announcement/" + courseId + "/",
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const changePassword = async (header, newPassword) => {
  try {
    const res = await instance.put(
      `api/user/change-password/`,
      {
        password: newPassword,
      },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getMockTestsData = async (header, courseId) => {
  try {
    const res = await instance.get("api/learning-app/user/mock-tests/" + courseId + "/", {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getMockTestQuestionsData = async (header, quizId) => {
  try {
    const res = await instance.get(
      "api/learning-app/user/mock-test-details/" + quizId + "/",
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createMockTestAttempt = async (header, quizId, attemptData) => {
  try {
    const res = await instance.post(
      "api/learning-app/user/attempt/" + quizId + "/",
      attemptData,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createChapterProgress = async (header, chapter_id) => {
  try {
    const res = await instance.post(
      `api/learning-app/user/chapter-progress/${chapter_id}/`,
      null,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateChapterProgress = async (header, chapter_id, payload) => {
  try {
    const res = await instance.put(
      `api/learning-app/user/chapter-progress/${chapter_id}/`,
      payload,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getCourseCertificate = async (header, courseId) => {
  try {
    const res = await instance.get(
      "api/learning-app/user/get-certificate/" + courseId + "/",
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const sendCourseCertificate = async (header, courseId) => {
  try {
    const res = await instance.get(
      "api/learning-app/user/send-certificate/" + courseId + "/",
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const downloadCourseCertificate = async (header, courseId) => {
  try {
    const res = await instance.get(
      `api/learning-app/user/download-certificate/${courseId}/`,
      {
        headers: header,
        responseType: "blob",
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const fetchPDFfromS3 = async (url) => {
  try {
    const res = await axios.get(url,
      {
        responseType: "arraybuffer",
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getScormData = async (header, scormProgressId) => {
  try {
    const res = await instance.get(
      "api/learning-app/user/scorm-progress/" + scormProgressId + "/",
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const setScormData = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/learning-app/user/scorm-progress/",
      payload,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateScormData = async (header, scormProgressId, payload) => {
  try {
    const res = await instance.patch(
      "api/learning-app/user/scorm-progress/" + scormProgressId + "/",
      {
        data: payload
      },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const scormCourseStatus = async (header, payload) => {
  try {
    const res = await instance.post(
      "api/learning-app/user/user-course/finish-course/",
      payload,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getImsManifestFile = async (url, header) => {
  try {
    const res = await instance.get(
      url,{ 
        withCredentials: true ,
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
}