import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./AddStaffPopup.scss";
import "../../../theme/_buttons.scss";
import toast from "react-hot-toast";
import ToggleSwitch from "../../../common/components/ToggleSwitch";
import SearchableDropdown from "../../../common/components/SearchableDropdown";
import ApiSearchableDropdown from "../components/ApiSearchableDropdown";
import { createStaff, getUserData } from "../services/api.services";

const AddStaffPopup = ({ header, jobroles, setKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [staffData, setStaffData] = useState([]);
  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    getStaffData();
  }, [pageNo, searchKey]);

  const getStaffData = async () => {
    try {
      const response = await getUserData(
        header,
        pageNo,
        recordsPerPage,
        null,
        null,
        null,
        null,
        searchKey
      );
      if (response.status === 200) {
        setDataCount(response.data.count);
        setStaffData(response.data.results);
      } else {
        toast.error("Failed to fetch staff data");
      }
    } catch (error) {
      toast.error("Error fetching staff data: " + error.message);
    }
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    jobrole: "",
    manager: "",
    password: "",
    is_admin: isAdmin,
  });

  const roleDropdownItems = jobroles.map((item) => ({
    label: item.name,
    handleClick: () =>
      setFormData((prevData) => ({
        ...prevData,
        jobrole: item.name,
      })),
  }));

  const managerDropdownItems = staffData.map((item) => ({
    label: item.full_name,
    handleClick: () =>
      setFormData((prevData) => ({
        ...prevData,
        manager: item.id,
      })),
  }));

  const customStyle = {
    marginBlockStart: "7px",
    marginBlockEnd: "15px",
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      jobrole: "",
      manager: "",
      password: "",
      is_admin: false,
    });
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };
  const emailRegex = new RegExp(
    `[a-z0-9À-ÖØ-öø-ÿ._%+-]+@[a-zÀ-ÖØ-öø-ÿ.-]+\\.[a-z]{2,}`,
    "i"
  );
  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nameRegex.test(formData.firstname)) {
      setError(
        "First name can only contain letters, spaces, hyphens, and apostrophes."
      );
      return;
    }
    if (!nameRegex.test(formData.lastname)) {
      setError(
        "Last name can only contain letters, spaces, hyphens, and apostrophes."
      );
      return;
    }
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if (!formData.jobrole) {
      setError("Job role is required.");
      return;
    }
    if (!formData.manager) {
      setError("Manager is required.");
      return;
    }
    if (formData.password.length < 8 || formData.password.length > 16) {
      setError("Password must be between 8 and 16 characters long.");
      return;
    }
    setError("");
    try {
      const response = await createStaff(header, formData);
      if (response.status === 200) {
        toast.success("Staff member added successfully!");
        toggleModal();
        setKey((prevKey) => prevKey + 1)
      } else {
        toast.error(Object.values(response.message)[0] || "Error adding staff member.");
      }
    } catch (error) {
      toast.error("Error adding staff member.");
    }
  };

  const handleToggleChange = (isToggled) => {
    setFormData((prevData) => ({
      ...prevData,
      is_admin: !isAdmin,
    }));
  };
  const onSearch = async (searchKey, pageNo) => {
    const response = await getUserData(
      header,
      pageNo,
      5,
      null,
      null,
      null,
      null,
      searchKey
    );
    if (response.status === 200) {
      if (pageNo === 1) {
        setStaffData(response.data.results);
      } else {
        setStaffData((prevData) => [...prevData, ...response.data.results]);
      }
      setDataCount(response.data.count);
    } else {
      toast.error("Failed to fetch data");
    }
  };

  return (
    <div>
      <button className="primary-button" onClick={toggleModal}>
        + Add Staff
      </button>

      {isOpen && (
        <div className="add_staff_modal-overlay">
          <div className="add_staff_modal-content">
            <div
              onClick={toggleModal}
              className="add_staff_modal-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="add_staff_modal-content-title">Add Staff</div>

            <form onSubmit={handleSubmit}>
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                id="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />

              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                id="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />

              {/* Password Input */}
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
              />

              {/* Role Dropdown */}
              <label htmlFor="role">Role</label>
              <SearchableDropdown
                items={roleDropdownItems}
                defaultText="Role"
                customStyle={customStyle}
                allowNewItems={true}
                onNewItem={(newItem) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    jobrole: newItem,
                  }))
                }
              />

              {/* Assigned Manager Dropdown */}
              <label htmlFor="assignedManager">Assigned Manager</label>
              <ApiSearchableDropdown
                items={managerDropdownItems}
                defaultText="Select Manager"
                onChange={null}
                onSearch={onSearch}
                totalItemsCount={dataCount}
                allowNewItems={false}
              />

              <label htmlFor="is_admin">Admin</label>
              <ToggleSwitch
                checked={false}
                onChange={handleToggleChange}
                customStyles={{
                  switchBackground: "#ccc",
                  sliderBackground: "#ffffff",
                  switchCheckedBackground: "rgb(87 155 214)",
                  sliderCheckedBackground: "#ffffff",
                }}
              />
              {/* Error div for displaying the top-most validation error */}
              {error && (
                <div style={{ color: "red", marginBlock: "5px",  fontSize: "12px", textAlign:"center" }}>

                  {error}
                </div>
              )}
              <div className="add_staff_modal-content-bottom">
                <div className="add_staff_modal-content-bottom-buttons">
                  <button
                    className="add_staff_modal-content-bottom-buttons-submit"
                    type="submit"
                  >
                    Add
                  </button>
                  <button
                    onClick={toggleModal}
                    className="add_staff_modal-content-bottom-buttons-cancel"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddStaffPopup;
