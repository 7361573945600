import React from "react";
import "./EmptyContainer.scss";
import noDataIcon from "../../learning/images/icons/incomplete.png"

const EmptyContainer = ({ message, style = {} }) => {
  return (
    <div className="empty-container" style={style}>
      <div className="empty-container-image">
        <img src={noDataIcon} alt="no data" />
      </div>
      <div className="empty-container-text">{message}</div>
    </div>
  );
};

export default EmptyContainer;
