import React from "react";
import "./AppPagination.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const AppPagination = ({
  setCurrentPage,
  currentPage,
  recordsPerPage,
  data_count,
}) => {
  const pages = Math.ceil(data_count / recordsPerPage);
  const numbers = [...Array(pages + 1).keys()].slice(1);

  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage !== pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const renderPageNumbers = () => {
    const maxVisiblePages = 3; // Only three pages in the middle
    const firstPage = 1;
    const lastPage = pages;

    const pageItems = [];

    // Always include the first page
    pageItems.push(
      <li
        className={`app-pagination_nav-list-item ${
          currentPage === firstPage ? "active" : ""
        }`}
        key="first"
      >
        <div
          className="app-pagination_nav-list-item-link"
          onClick={() => handleChangePage(firstPage)}
        >
          {firstPage}
        </div>
      </li>
    );

    // Add "..." if there are pages omitted before the middle range
    if (currentPage > maxVisiblePages + 1) {
      pageItems.push(
        <li className="dots" key="dots-left">
          ...
        </li>
      );
    }

    // Determine start and end of the middle range
    const startPage = Math.max(currentPage - 1, 2);
    const endPage = Math.min(currentPage + 1, pages - 1);

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(
        <li
          className={`app-pagination_nav-list-item ${
            currentPage === i ? "active" : ""
          }`}
          key={i}
        >
          <div
            className="app-pagination_nav-list-item-link"
            onClick={() => handleChangePage(i)}
          >
            {i}
          </div>
        </li>
      );
    }

    // Add "..." if there are pages omitted after the middle range
    if (currentPage < pages - maxVisiblePages) {
      pageItems.push(
        <li className="dots" key="dots-right">
          ...
        </li>
      );
    }

    // Always include the last page
    if (pages > 1) {
      pageItems.push(
        <li
          className={`app-pagination_nav-list-item ${
            currentPage === lastPage ? "active" : ""
          }`}
          key="last"
        >
          <div
            className="app-pagination_nav-list-item-link"
            onClick={() => handleChangePage(lastPage)}
          >
            {lastPage}
          </div>
        </li>
      );
    }

    return pageItems;
  };

  return (
    <div className="app-pagination">
      <nav className="app-pagination_nav">
        <ul className="app-pagination_nav-list">
          {currentPage > 1 && (
            <li className="app-pagination_nav-list-item nav">
              <div
                className="app-pagination_nav-list-item-link"
                onClick={handlePrevPage}
              >
                <FaChevronLeft />
              </div>
            </li>
          )}
          {renderPageNumbers()}
          {currentPage < pages && (
            <li className="app-pagination_nav-list-item nav">
              <div
                className="app-pagination_nav-list-item-link"
                onClick={handleNextPage}
              >
                <FaChevronRight />
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default AppPagination;
