import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { getSessionInfo, updateCreditBalance } from "../services/api.services";
import "./AdminPaymentSuccess.scss";
import { useCredit } from "./CreditContext";

function AdminPaymentSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [sessionData, setSessionData] = useState({});
  const { fetchCreditDetails, isSubscription } = useCredit();
  let header = {
    Authorization: "Token " + state.token,
  };
  useEffect(() => {
    const SessionStatus = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get("session_id");

      if (sessionId) {
        try {
          const response = await getSessionInfo(header, sessionId);
          if (response.status === 200) {
            setSessionData(response.data.sessionInfo);
            if (!response.data.sessionInfo.isCreditUpdated) {
              updateCredits(
                response.data.sessionInfo.credits_purchased,
                sessionId
              );
            } else {
              // toast.success("Credit points already added");
            }
          } else {
            console.error(
              "Error fetching session status:",
              response.data.error
            );
          }
        } catch (error) {
          console.error("Error fetching session status:", error);
        }
      }
    };

    SessionStatus();
  }, [location.search]);

  const updateCredits = async (credits_purchased, sessionId) => {
    try {
      const response = await updateCreditBalance(
        header,
        credits_purchased,
        true,
        sessionId
      );
      if (response.data.isSuccess) {
        if(!isSubscription){
          fetchCreditDetails();
        }
        toast.success("Credit points added successfully");
      } else {
        toast.error("Failed to purchase credits");
      }
    } catch (error) {
      toast.error("Error fetching purchasing");
    }
  };

  const handleClick = async () => {
    navigate("/learning/admin/credits");
  };
  
  return (
    <div className="payment_success_page">
      <div className="payment_success_page-body">
        <div className="payment_success_page-body-top">
          <div className="payment_success_page-body-top-icon">
            {sessionData.status === "failed" && (
              <FaTimesCircle
                style={{
                  width: "85px",
                  height: "85px",
                  color: "rgb(233 101 107)",
                }}
              />
            )}
            {sessionData.status === "complete" && (
              <FaCheckCircle
                style={{ width: "85px", height: "85px", color: "#1ccda6" }}
              />
            )}
          </div>
          {sessionData.status === "complete" && (
            <div className="payment_success_page-body-top-text">
              Payment Successfull
            </div>
          )}
          {sessionData.status === "failed" && (
            <div className="payment_success_page-body-top-text">
              Payment Failed
            </div>
          )}
        </div>
        {sessionData.status === "complete" && (
          <div className="payment_success_page-body-middle">
            <div className="payment_success_page-body-middle-info">
              <div className="payment_success_page-body-middle-info-row">
                <div className="payment_success_page-body-middle-info-row-key">
                  Payee
                </div>
                <div className="payment_success_page-body-middle-info-row-value">
                  {sessionData.customer_name}
                </div>
              </div>
              <div className="payment_success_page-body-middle-info-row">
                <div className="payment_success_page-body-middle-info-row-key">
                  Email
                </div>
                <div className="payment_success_page-body-middle-info-row-value">
                  {sessionData.customer_email}
                </div>
              </div>
              <div className="payment_success_page-body-middle-info-row">
                <div className="payment_success_page-body-middle-info-row-key">
                  Payment Status
                </div>
                <div className="payment_success_page-body-middle-info-row-value">
                  {sessionData.status}
                </div>
              </div>
              <div className="payment_success_page-body-middle-info-row">
                <div className="payment_success_page-body-middle-info-row-key">
                  Credits Purchased
                </div>
                <div className="payment_success_page-body-middle-info-row-value">
                  {sessionData.credits_purchased}
                </div>
              </div>
            </div>
            <div className="payment_success_page-body-middle-amount">
              <div className="payment_success_page-body-middle-amount-key">
                Amount Paid
              </div>
              <div className="payment_success_page-body-middle-amount-value">
                £ {sessionData.total_amount}
              </div>
            </div>
            <div className="payment_success_page-body-middle-transaction">
              Transaction Id : {sessionData.transaction_id}
            </div>
          </div>
        )}
        {sessionData.status === "failed" && (
          <div className="payment_success_page-body-error-bottom">
            <div
              className="payment_success_page-body-error-bottom-try"
              onClick={handleClick}
            >
              Try Again
            </div>
            <div
              className="payment_success_page-body-error-bottom-back"
              onClick={handleClick}
            >
              Go Back
            </div>
          </div>
        )}
        {sessionData.status === "complete" && (
          <div className="payment_success_page-body-bottom">
            <div
              className="payment_success_page-body-bottom-button"
              onClick={handleClick}
            >
              Continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPaymentSuccess;
