import React, { Component } from "react";
import "./GridLayout.scss";

export default class GridLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPerRow: this.calculateItemsPerRow(),
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ itemsPerRow: this.calculateItemsPerRow() });
  };

  calculateItemsPerRow = () => {
    const width = window.innerWidth;
    if (width > 1600) {
      return 5;
    } else if (width > 1440) {
      return 4;
    } else if (width > 1150) {
      return 3;
    } else if (width > 600) {
      return 2;
    } else {
      return 1;
    }
  };

  render() {
    const { itemsPerRow } = this.state;
    const children = this.props.children;
    const itemWidth = itemsPerRow === 1 ? "100%" : `${100 / itemsPerRow}%`;

    return (
      <div className="grid-layout">
        <div style={{ display: "flex", flexWrap: "wrap", width:"100%" }}>
          {children.map((value, index) => (
            <div className="item" key={index} style={{ width: itemWidth }}>
              {value}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
