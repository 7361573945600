import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaChevronDown } from "react-icons/fa";
import "./ApiSearchableDropdown.scss";

const ApiSearchableDropdown = ({
  items,
  defaultText,
  onChange,
  onSearch,
  totalItemsCount,
  customStyle = {},
  allowNewItems = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input to focus

  // Update items when new items are passed from parent
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  // Handle item click
  const handleItemClick = (item) => {
    setSelectedItem(item);
    item.handleClick();
    setSearchTerm(item.label);
    setIsOpen(false);
  };

  // Handle input change and trigger API search
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page on new search
    onSearch(e.target.value, 1); // Call parent's search function
  };

  // Fetch more items when scrolled to the end
  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      !loading &&
      filteredItems.length < totalItemsCount // Check if more items are available
    ) {
      loadMoreItems();
    }
  };

  const loadMoreItems = useCallback(async () => {
    setLoading(true);
    const nextPage = page + 1;
    setPage(nextPage);
    await onSearch(searchTerm, nextPage);
    setLoading(false);
  }, [page, searchTerm, onSearch]);

  // Set up outside click handler
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Focus input when dropdown is open
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="searchable-dropdown" style={customStyle} ref={dropdownRef}>
      <div
        className="searchable-dropdown__toggle"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {isOpen ? (
          <input
          style={{margin:"0", padding:"0"}}
            type="text"
            ref={inputRef} // Set focus on this input
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search..."
            className="searchable-dropdown__input"
          />
        ) : (
          <span>{selectedItem ? selectedItem.label : defaultText}</span>
        )}
        <FaChevronDown
          className={`chevron-icon ${isOpen ? "rotate" : ""}`}
        />
      </div>
      {isOpen && (
        <ul
          className={`searchable-dropdown__list ${isOpen ? "open" : ""}`}
          onScroll={handleScroll}
        >
          {filteredItems.length ? (
            filteredItems.map((item, index) => (
              <li
                key={index}
                className="searchable-dropdown__list-item"
                onClick={() => handleItemClick(item)}
              >
                {item.label}
              </li>
            ))
          ) : !loading ? (
            <li className="searchable-dropdown__list-item no-items">
              No items found
            </li>
          ) : null}
          {filteredItems.length < totalItemsCount && !loading && (
            <li className="searchable-dropdown__list-item view-more" onClick={loadMoreItems}>
              View More
            </li>
          )}
          {loading && (
            <li className="searchable-dropdown__list-item loading">
              Loading...
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ApiSearchableDropdown;
