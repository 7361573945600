import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
const clearLocalStorage = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
  }
};

export const updateUserPassword = async (
  header,
  currentPassword,
  newPassword
) => {
  try {
    const res = await instance.put(
      `api/user/change-password/`,
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getUserInfo = async (header) => {
  try {
    const res = await instance.get("api/user/user-info/", { headers: header });
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const updateUserDetails = async (header, userId, editedUserDetails) => {
  try {
    const res = await instance.patch(
      `api/user/user-info/${userId}/`,
      editedUserDetails,
      {
        headers: header,
      }
    );
    return res;
  } catch (err) {
    clearLocalStorage(err);
    return { status: "error", message: err };
  }
};

export const getJobroles = async (header, searchKey = null) => {
  try {
    let url = "api/user-app/admin/jobrole/";
    if (searchKey) {
      url += `?search=${searchKey}`;
    }
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const createJobrole = async (header, name) => {
  const formData = new FormData();
  formData.append("name", name);
  try {
    let url = "api/user-app/admin/jobrole/";
    const res = await instance.post(url, formData, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const removeJobrole = async (header, jobroleId) => {
  try {
    let url = `api/user-app/admin/jobrole/${jobroleId}/`;
    const res = await instance.delete(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err.message };
  }
};

export const getOrganisationData = async (header) => {
  try {
    const url = "api/user-app/admin/organisation/";
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateOrganisation = async (header, orgId, formData) => {
  try {
    const res = await instance.patch(
      `api/user-app/admin/organisation/${orgId}/`,
      formData,
      {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
