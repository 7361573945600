import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaRegTrashAlt, FaEye } from "react-icons/fa";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import AppPagination from "../../../learning/admin/components/AppPagination";
import noDataImage from "../../../learning/images/icons/incomplete.png";
import { AuthContext } from "../../../App";
import "../../../theme/_buttons.scss";
import {
  getPolicytData,
  searchPolicyData,
  removePolicyData,
  getTemplateData,
  removeTemplateData,
  searchTemplateData,
} from "../services/api.services";
import "./AdminPolicy.scss";
import EmptyContainer from "../../../common/components/EmptyContainer";
import AppLoader from "../../../learning/user/components/AppLoader";

const AdminPolicy = () => {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("policy");
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [checkedData, setCheckedData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [policyData, setPolicyData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response;
        if (selectedTab === "policy") {
          if (searchKey) {
            response = await searchPolicyData(
              header,
              searchKey,
              pageNo,
              recordsPerPage
            );
          } else {
            response = await getPolicytData(header, pageNo, recordsPerPage);
          }
        } else if (selectedTab === "template") {
          if (searchKey) {
            response = await searchTemplateData(
              header,
              searchKey,
              pageNo,
              recordsPerPage
            );
          } else {
            response = await getTemplateData(header, pageNo, recordsPerPage);
          }
        }

        if (response.status !== "error") {
          setDataCount(response.data.count);

          // Update state based on selected tab
          if (selectedTab === "policy") {
            setPolicyData(response.data.results);
          } else if (selectedTab === "template") {
            setTemplateData(response.data.results);
          }
        } else {
          toast.error("Error fetching data: " + response.message);
        }
      } catch (err) {
        toast.error("An unexpected error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedTab, searchKey, pageNo, recordsPerPage, key]);

  let header = {
    Authorization: "Token " + state.token,
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCheckedData([]);
    setPageNo(1);
  };

  const handleNavigate = () => {
    navigate("/policy/admin/create-policy");
  };

  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }

    try {
      let response;

      if (selectedTab === "policy") {
        response = await removePolicyData(header, checkedData);
        if (response.status === 200) {
          toast.success("Data removed successfully.");
        } else {
          toast.error(`Error: ${response.message}`);
        }
      } else if (selectedTab === "template") {
        response = await removeTemplateData(header, checkedData);
        if (response.status === 200) {
          toast.success("Template data deleted successfully.");
        } else {
          toast.error(`Error: ${response.message}`);
        }
      }

      setCheckedData([]);
      setIsRemove(false);
      setKey(!key);
    } catch (error) {
      console.error("Failed to delete data:", error);
      toast.error("An error occurred while deleting.");
    }
  };
  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  const handleDetailClick = (data) => {
    if (selectedTab === "template") {
      navigate("/compliance/admin/survey-templates", { state: data });
    }
    if (selectedTab === "recipientList") {
      navigate("/compliance/admin/survey-recipient_list", { state: data });
    }
    if (selectedTab === "survey") {
      navigate("/compliance/admin/survey-details", { state: data });
    }
  };
  const formatFullFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };
  const formatFileName = (url) => {
    const fullFileName = url.split("/").pop().split("?")[0];
    const maxLength = 20;
    if (fullFileName.length <= maxLength) {
      return fullFileName;
    }
    const start = fullFileName.slice(0, 10);
    const end = fullFileName.slice(-10);
    return `${start}...${end}`;
  };

  return (
    <div className="admin-policy">
      <div className="admin-policy-title">Policy</div>

      <div className="admin-policy-tabs">
        <div
          className={`admin-policy-tabs-tab ${
            selectedTab === "policy" ? "active" : ""
          }`}
          onClick={() => handleTabClick("policy")}
        >
          Policy
        </div>
        <div
          className={`admin-policy-tabs-tab ${
            selectedTab === "template" ? "active" : ""
          }`}
          onClick={() => handleTabClick("template")}
        >
          Template
        </div>
      </div>

      <div className="admin-policy-body">
        <div className="admin-policy-body-tab-content">
          <div className="admin-policy-body-tab-content-top">
            {selectedTab === "policy" && (
              <button
                className="primary-button"
                style={{ width: "120px" }}
                onClick={() => handleNavigate()}
              >
                <FaPlus />
                New Policy
              </button>
            )}
            <div className="admin-policy-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          <div className="responsive-container">
            <div className="admin-policy-body-tab-content-table">
              <div className="admin-policy-body-tab-content-table-head">
                {selectedTab === "policy" && (
                  <>
                    <div className="admin-policy-body-tab-content-table-head-item">
                      <AdminCustomCheckbox
                        selectAll={true}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                        responseData={policyData}
                      />
                      Document Name
                    </div>
                    <div className="admin-policy-body-tab-content-table-head-item">
                      Date
                    </div>
                    <div className="admin-policy-body-tab-content-table-head-item">
                      Updated by
                    </div>
                    <div className="admin-policy-body-tab-content-table-head-item">
                      Version
                    </div>
                    <div className="admin-policy-body-tab-content-table-head-item">
                      Status
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      <FaRegTrashAlt
                        onClick={() => {
                          checkedData.length > 0
                            ? setIsRemove(true)
                            : handleRemoveData();
                        }}
                        className={itemClass}
                      />
                    </div>
                  </>
                )}
                {selectedTab === "template" && (
                  <>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Template Name
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Date
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      File
                    </div>
                  </>
                )}
              </div>
              <div className="admin-policy-body-tab-content-table-body">
                {selectedTab === "policy" &&
                  (policyData.lenght !== 0 ? (
                    <>
                      {policyData.map((item) => (
                        <div
                          className="admin-policy-body-tab-content-table-body-row"
                          key={item.id}
                        >
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            <AdminCustomCheckbox
                              selectAll={false}
                              data_id={item.id}
                              setCheckedData={setCheckedData}
                              checkedData={checkedData}
                            />
                            <div className="admin-policy-body-tab-content-table-body-row-item-text">
                              <div className="admin-policy-body-tab-content-table-body-row-item-text-main">
                                {item.name && item.name.length > 0
                                  ? item.name
                                  : item.policy_number}
                              </div>
                              <div className="admin-policy-body-tab-content-table-body-row-item-text-sub">
                                Last modified on {formatDate(item.updated_on)}
                              </div>
                            </div>
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            {formatDate(item.created_on)}
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            {item.request_data?.policy_author
                              ? item.request_data.policy_author
                              : "Uploaded Template"}
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            {item.request_data?.version
                              ? item.request_data.version
                              : "1.0"}
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            {item.status}
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            <div
                              className={`admin-policy-body-tab-content-table-body-row-item-button ${
                                openDropdownId === item.id ? "open" : ""
                              }`}
                              style={{ width: "95px" }}
                              onClick={() =>
                                handleFileDownload(
                                  item.file,
                                  `${formatFileName(item.file)}.pdf`
                                )
                              }
                            >
                              <FaEye />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <EmptyContainer
                      message="No data available to display.!!"
                      style={{
                        height: "80vh",
                        justifyContent: "start",
                        paddingBlockStart: "80px",
                        boxShadow: "none",
                      }}
                    />
                  ))}
                {selectedTab === "template" &&
                  (templateData.length > 0 ? (
                    <>
                      {templateData.map((item) => (
                        <div
                          className="admin-policy-body-tab-content-table-body-row"
                          key={item.id}
                        >
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            <div className="admin-policy-body-tab-content-table-body-row-item-text">
                              <div className="admin-policy-body-tab-content-table-body-row-item-text-main">
                                {item.name}
                              </div>
                              <div className="admin-policy-body-tab-content-table-body-row-item-text-sub">
                                Last modified on {formatDate(item.updated_on)}
                              </div>
                            </div>
                          </div>
                          <div className="admin-policy-body-tab-content-table-body-row-item">
                            {formatDate(item.created_on)}
                          </div>
                          <div className="admin-survey-body-tab-content-table-body-row-item">
                            <span
                              className="link"
                              title={formatFullFileName(item.file, true)}
                              onClick={() =>
                                navigate("/policy/admin/policy-template-view", {
                                  state: item,
                                })
                              }
                            >
                              {formatFileName(item.file)}
                            </span>
                          </div>
                          {/* <div className="admin-policy-body-tab-content-table-body-row-item">
                      <div
                        className={`secondary-button admin-policy-body-tab-content-table-body-row-item-button ${
                          openDropdownId === item.id ? "open" : ""
                        }`}
                        style={{ width: "120px" }}
                        // onClick={() => handleDetailClick(item)}
                      >
                        <FaEye /> View Details
                      </div>
                    </div> */}
                        </div>
                      ))}
                    </>
                  ) : (
                    <EmptyContainer
                      message="No data available to display.!!"
                      style={{
                        height: "80vh",
                        justifyContent: "start",
                        paddingBlockStart: "80px",
                        boxShadow: "none",
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove {capitalizeFirstLetter(selectedTab)}
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? selectedTab : `${selectedTab}(s)`}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminPolicy;
