import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./CourseSelector.scss";
import myLearningsIcon from "../../images/icons/curriculum.png";
import {
  getUserCourseData,
  getUserCourses,
  searchUserCourses,
  getUserCourseNames
} from "../services/api.services";
import AppStartCourse from "../components/AppStartCourse";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import AppLoader from "../components/AppLoader";
import SearchBox from "../components/SearchBox";
import UserHeader from "../../../common/components/UserHeader";
import CommonSidebar from "../../../common/components/CommonSidebar";
import AppPagination from "../../admin/components/AppPagination";

const CourseSelector = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseNames, setCourseNames] = useState([]);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [navbarData, setNavbarData] = useState([
    { name: "My Learning", icon: myLearningsIcon },
  ]);
  const [status, setStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    getUserDetail();
    fetchUserCourseNames();
    localStorage.setItem("selectedNavTab", 0);
  }, []);

  useEffect(() => {
    if (searchText) {
      findUserCourse(searchText);
    } else {
      fetchUserCourses();
    }
  }, [status, searchText, pageNo]);

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);
  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  let header = {
    Authorization: "Token " + state.token,
  };

  const getUserDetail = async () => {
    await getUserCourseData(header)
      .then((resp) => {
        if (resp.data.isSuccess) {
          // setSelectedCourses(resp.data.dataInfo.course);
          // localStorage.setItem('userCourses', JSON.stringify(resp.data.dataInfo.course))
          localStorage.setItem(
            "userDetails",
            JSON.stringify(resp.data.dataInfo)
          );
          setLoader(true);
        } else {
          toast.error("Something went wrong!");
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setLoader(false);
      });
  };

  const fetchUserCourses = async () => {
    // setLoader(false);
    try {
      let response;
      if (status) {
        response = await getUserCourses(header, pageNo, recordsPerPage, status);
      } else {
        response = await getUserCourses(header, pageNo, recordsPerPage);
      }
      
      if (response.status === 200) {
        setSelectedCourses(response.data.results);
        setCount(response.data.count);
        localStorage.setItem(
          "userCourses",
          JSON.stringify(response.data.results)
        );
        setLoader(true);
      } else {
        toast.error("Failed to fetch Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error fetching userCourse data: " + error.message);
      setLoader(false);
    }
  };

  const findUserCourse = async (searchKey) => {
    // setLoader(false);
    try {
      let response;
      if (status) {
        response = await searchUserCourses(header, searchKey, status);
      } else {
        response = await searchUserCourses(header, searchKey);
      }

      if (response.status === 200) {
        setSelectedCourses(response.data.results);
        setCount(response.data.count);
        setLoader(true);
      } else {
        toast.error("Failed to Search Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error searching Course data: " + error.message);
      setLoader(false);
    }
  };

  const fetchUserCourseNames = async (searchKey) => {
    try {
      const response = await getUserCourseNames(header, status);
  
      if (response.status === 200) {
        setCourseNames(response.data.dataInfo); 
        setLoader(true);
      } else {
        toast.error("Failed to fetch course names");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error fetching course names: " + error.message);
      setLoader(false);
    }
  };
  

  const handleCourseSelect = (courseName) => {
    findUserCourse(courseName);
    setSearchText(courseName);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleHamburgerNav = () => {
    setOpenMobileNav(true);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      {!loader ? (
        <AppLoader />
      ) : (
        <div className="app-course-selector">
          {/* <div className="app-mobile-header">
            <div
              className="app-mobile-header-hamburger"
              onClick={handleHamburgerNav}
            >
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
            </div>
            <div className="app-mobile-header-logo">
              <div className="app-mobile-header-logo-img">
                <img src={logo} alt="logo"></img>
              </div>
            </div>
            <div className="app-mobile-header-sidebar-button">
              <img src={avatar} alt="userimage"></img>
            </div>
          </div> */}
          <CommonSidebar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          ></CommonSidebar>

          <div className="app-course-selector-body">
            <div className="app-course-selector-body-user">
              <UserHeader toggleSidebar={handleHamburgerNav} />
            </div>
            <div className="app-course-selector-body-title">
              <div className="app-course-selector-body-title-text">
                Select Your Course
              </div>
              <div className="app-course-selector-body-title-search-box">
                <SearchBox
                  searchData={courseNames}
                  onValueClick={handleCourseSelect}
                  onClearSearch={handleClearSearch}
                />
              </div>
            </div>
            <AppStartCourse
              selectedCourses={selectedCourses}
              isStartButton={true}
              header={header}
              setStatus={setStatus}
              status={status}
              courseCount={count}
              setPageNo={setPageNo}
            />
            {count > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={count}
            />
          )}
          </div>
        </div>
      )}
    </>
  );
};

export default CourseSelector;
