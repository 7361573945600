import React, { useState, useEffect } from "react";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import "./EditStaffPopup.scss";
import "../../../theme/_buttons.scss";
import toast from "react-hot-toast";
import ToggleSwitch from "../../../common/components/ToggleSwitch";
import SearchableDropdown from "../../../common/components/SearchableDropdown";
import ApiSearchableDropdown from "../components/ApiSearchableDropdown";
import { updateStaff, getUserData } from "../services/api.services";

const EditStaffPopup = ({ header, data, jobroles, key, setKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [staffData, setStaffData] = useState([]);
  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    jobrole: "",
    manager: "",
    is_admin: data.is_admin,
  });
  const [modifiedFields, setModifiedFields] = useState({});

  useEffect(() => {
    getStaffData();
  }, [pageNo, searchKey]);

  const getStaffData = async () => {
    try {
      const response = await getUserData(
        header,
        pageNo,
        recordsPerPage,
        null,
        null,
        null,
        null,
        searchKey
      );
      if (response.status === 200) {
        setDataCount(response.data.count);
        setStaffData(response.data.results);
      } else {
        toast.error("Failed to fetch staff data");
      }
    } catch (error) {
      toast.error("Error fetching staff data: " + error.message);
    }
  };
  useEffect(() => {
    if (data) {
      setFormData({
        firstname: data.first_name || "",
        lastname: data.last_name || "",
        email: data.email || "",
        jobrole: data.jobrole || "",
        manager: data.manager ? data.manager.id : "",
        is_admin: data.is_admin || false,
      });
    }
  }, [data]);

  const customStyle = {
    marginBlockStart: "7px",
    marginBlockEnd: "15px",
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));
    setModifiedFields((prevFields) => ({
      ...prevFields,
      [id]: newValue,
    }));
  };

  const roleDropdownItems = jobroles.map((item) => ({
    label: item.name,
    handleClick: () => {
      setFormData((prevData) => ({
        ...prevData,
        jobrole: item.name,
      }));
      setModifiedFields((prevFields) => ({
        ...prevFields,
        jobrole: item.name,
      }));
    },
  }));

  const managerDropdownItems = staffData.map((item) => ({
    label: item.full_name,
    handleClick: () => {
      setFormData((prevData) => ({
        ...prevData,
        manager: item.id,
      }));
      setModifiedFields((prevFields) => ({
        ...prevFields,
        manager: item.id,
      }));
    },
  }));

  const handleToggleChange = () => {
    const newIsAdminValue = !formData.is_admin;
    setFormData((prevData) => ({
      ...prevData,
      is_admin: newIsAdminValue,
    }));
    setModifiedFields((prevFields) => ({
      ...prevFields,
      is_admin: newIsAdminValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateStaff(header, data.id, modifiedFields);
      if (response.status === 200) {
        toast.success("Details updated successfully!");
        setKey(prevKey => prevKey + 1);
        toggleModal();
        setModifiedFields({});
      } else {
        toast.error(response.message || "Error updating staff details.");
      }
    } catch (error) {
      toast.error("Error updating staff details.");
    }
  };
  const onSearch = async (searchKey, pageNo) => {
    const response = await getUserData(
      header,
      pageNo,
      5,
      null,
      null,
      null,
      null,
      searchKey
    );
    if (response.status === 200) {
      if (pageNo === 1) {
        setStaffData(response.data.results);
      } else {
        setStaffData((prevData) => [...prevData, ...response.data.results]);
      }
      setDataCount(response.data.count);
    } else {
      toast.error("Failed to fetch data");
    }
  };

  return (
    <div>
      <button className="secondary-button" onClick={toggleModal}>
        <FaPencilAlt /> Edit
      </button>

      {isOpen && (
        <div className="edit_staff_modal-overlay">
          <div className="edit_staff_modal-content">
            <div
              onClick={toggleModal}
              className="edit_staff_modal-content-close_button"
            >
              <FaTimes />
            </div>

            <div className="edit_staff_modal-content-title">Edit Staff</div>

            <form onSubmit={handleSubmit}>
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                id="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />

              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                id="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />

              <label htmlFor="role">Role</label>
              <SearchableDropdown
                items={roleDropdownItems}
                defaultText={data.jobrole || "Select Role"}
                customStyle={customStyle}
                allowNewItems={true}
                onNewItem={(newItem) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    jobrole: newItem,
                  }));
                  setModifiedFields((prevFields) => ({
                    ...prevFields,
                    jobrole: newItem,
                  }));
                }}
              />

              <label htmlFor="manager">Assigned Manager</label>
              <ApiSearchableDropdown
                items={managerDropdownItems}
                defaultText={
                  data.manager ? data.manager.full_name : "Assign Manager"
                }
                onChange={null}
                onSearch={onSearch}
                totalItemsCount={dataCount}
                allowNewItems={false}
              />

              <label htmlFor="is_admin">Admin</label>
              <ToggleSwitch
                checked={formData.is_admin}
                onChange={handleToggleChange}
                customStyles={{
                  switchBackground: "#ccc",
                  sliderBackground: "#ffffff",
                  switchCheckedBackground: "rgb(87 155 214)",
                  sliderCheckedBackground: "#ffffff",
                }}
              />

              <div className="edit_staff_modal-content-bottom">
                <div className="edit_staff_modal-content-bottom-buttons">
                  <button
                    className="edit_staff_modal-content-bottom-buttons-submit"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    onClick={toggleModal}
                    className="edit_staff_modal-content-bottom-buttons-cancel"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditStaffPopup;
