import React, { useState, useRef, useContext } from "react";
import { FaTimes, FaUser, FaCog, FaPowerOff } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./UserAvatarDropdown.scss";
import { AuthContext } from "../../App";
import userAvatar from "../../learning/images/icons/user_avatar.jpg";

const UserAvatarDropdown = () => {
  const [userDropdownIsOpen, setUserDropdownIsOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const userAvatarRef = useRef(null);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
      payload: {
        user: null,
        token: null,
      },
    });
    navigate("/");
  };

  const toggleUserDropdown = () => {
    setUserDropdownIsOpen((prevState) => !prevState);
  };

  return (
    <div className="user-avatar-dropdown" ref={userAvatarRef}>
      <div className="user-avatar-dropdown-image" onClick={toggleUserDropdown}>
        {userDetails?.avatar ? (
          <img
            id="user_avatar_img"
            src={userDetails.avatar}
            alt="User Avatar"
          />
        ) : (
          <img id="user_avatar_img" src={userAvatar} alt="Default Avatar" />
        )}
      </div>
      {userDropdownIsOpen && (
        <div className="user-avatar-dropdown-dropdown_container">
          <div className="user-avatar-dropdown-dropdown_container-close">
            <FaTimes onClick={toggleUserDropdown} />
          </div>
          <div className="user-avatar-dropdown-dropdown_container-body">
            <div className="user-avatar-dropdown-dropdown_container-body-top">
              <div className="user-avatar-dropdown-dropdown_container-body-top-user_avatar">
                {userDetails?.avatar ? (
                  <img src={userDetails.avatar} alt="User Avatar" />
                ) : (
                  <img src={userAvatar} alt="Default Avatar" />
                )}
              </div>
              <div className="user-avatar-dropdown-dropdown_container-body-top-name">
                {userDetails?.full_name || "Admin"}
              </div>
              <div className="user-avatar-dropdown-dropdown_container-body-top-email">
                {userDetails?.email}
              </div>
            </div>
            <div className="user-avatar-dropdown-dropdown_container-body-tabs">
              <div
                className="user-avatar-dropdown-dropdown_container-body-tabs-item"
                onClick={() => navigate("/account-settings")}
              >
                <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-icon">
                  <FaUser />
                </div>
                <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-text">
                  My Account
                </div>
              </div>
              {state.is_admin === true && (
                <div
                  className="user-avatar-dropdown-dropdown_container-body-tabs-item"
                  onClick={() => navigate("/user-settings")}
                >
                  <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-icon">
                    <FaCog />
                  </div>
                  <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-text">
                    Settings
                  </div>
                </div>
              )}

              <div
                className="user-avatar-dropdown-dropdown_container-body-tabs-item"
                onClick={handleLogout}
              >
                <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-icon">
                  <FaPowerOff />
                </div>
                <div className="user-avatar-dropdown-dropdown_container-body-tabs-item-text">
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAvatarDropdown;
