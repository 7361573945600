import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminEnrollCourses.scss";
import { AuthContext } from "../../../App";
import {
  getAdminCourseData,
  enrollCourse,
  getCourseBundles,
  enrollCourseBundle,
} from "../services/api.services";
import GridLayout from "../components/GridLayout";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import PopupModal from "../components/PopupModal";
import { useCredit } from "../components/CreditContext";
import { FaRegEye, FaArrowLeft } from "react-icons/fa";
import "../../../theme/_buttons.scss";
import AppPagination from "../components/AppPagination";
import SearchBox from "../../user/components/SearchBox";
import DropdownButton from "../../../common/components/DropdownButton";
import EmptyContainer from "../../../common/components/EmptyContainer";
import AppLoader from "../../user/components/AppLoader";

const AdminEnrollCourses = () => {
  const location = useLocation();
  const [isBundleLoading, setIsBundleLoading] = useState(true);
  const [isCourseLoading, setIsCourseLoading] = useState(true);
  const { state, dispatch } = useContext(AuthContext);
  const [courseData, setCourseData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [enrollCourseIds, setEnrollCourseIds] = useState([]);
  const [enrollBundleIds, setEnrollBundleIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreditNeeded, setIsCreditNeeded] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [selectionType, setSelectionType] = useState(null); // "course" or "bundle"
  const [courseSearchKey, setCourseSearchKey] = useState("");
  const [bundleSearchKey, setBundleSearchKey] = useState("");
  const navigate = useNavigate();
  const { fetchCreditDetails, isSubscription } = useCredit();

  // for courseData
  const [courseRecordsPerPage, setCourseRecordsPerPage] = useState(6);
  const [coursePageNo, setCoursePageNo] = useState(1);
  const [courseCount, setCourseCount] = useState(0);
  const courseLimits = [
    { label: "6 Items", handleClick: () => setCourseRecordsPerPage(6) },
    { label: "12 Items", handleClick: () => setCourseRecordsPerPage(12) },
    { label: "24 Items", handleClick: () => setCourseRecordsPerPage(24) },
  ];

  // for bundleCourseData
  const [bundleRecordsPerPage, setBundleRecordsPerPage] = useState(5);
  const [bundlePageNo, setBundlePageNo] = useState(1);
  const [bundleCount, setBundleCount] = useState(0);
  const bundleLimits = [
    { label: "5 Items", handleClick: () => setBundleRecordsPerPage(5) },
    { label: "10 Items", handleClick: () => setBundleRecordsPerPage(10) },
    { label: "20 Items", handleClick: () => setBundleRecordsPerPage(20) },
  ];

  const customDropdownStyle = {
    boxShadow: "rgb(182 182 182) 0px 0px 1px 1px",
    color: "black",
    height: "40px",
    width: "110px",
    borderRadius: "10px",
    fontSize: "14px",
  };

  const trainees = location.state;

  useEffect(() => {
    getCourseData();
  }, [coursePageNo, courseRecordsPerPage, courseSearchKey]);
  useEffect(() => {
    getCourseBundlesData();
  }, [bundlePageNo, bundleRecordsPerPage, bundleSearchKey]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getCourseData = async () => {
    setIsCourseLoading(true);
    try {
      const response = await getAdminCourseData(
        header,
        coursePageNo,
        courseRecordsPerPage,
        courseSearchKey
      );
      if (response.status === 200) {
        // const courseData = response.data.dataInfo;
        setCourseData(response.data.results);
        setCourseCount(response.data.count);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    } finally {
      setIsCourseLoading(false);
    }
  };
  const getCourseBundlesData = async () => {
    setIsBundleLoading(true);
    try {
      const response = await getCourseBundles(
        header,
        bundlePageNo,
        bundleRecordsPerPage,
        bundleSearchKey
      );
      if (response.status === 200) {
        setBundleData(response.data.results);
        setBundleCount(response.data.count);
      } else {
        toast.error("Failed to fetch course bundles data");
      }
    } catch (error) {
      toast.error("Error fetching course bundle data: " + error.message);
    } finally {
      setIsBundleLoading(false);
    }
  };

  const handleCourseEnrollToggle = (courseId) => {
    if (selectionType === "bundle") {
      toast.error("Cannot select courses while bundles are selected");
      return;
    }

    setSelectionType("course");
    if (!enrollCourseIds.includes(courseId)) {
      setEnrollCourseIds([...enrollCourseIds, courseId]);
    } else {
      const updatedCourseIds = enrollCourseIds.filter(
        (item) => item !== courseId
      );
      setEnrollCourseIds(updatedCourseIds);

      // Enable both sections if no more courses are selected
      if (updatedCourseIds.length === 0) {
        setSelectionType(null);
      }
    }
  };

  const handleBundleSelect = (bundleId) => {
    if (selectionType === "course") {
      toast.error("Cannot select bundles while courses are selected");
      return;
    }

    setSelectionType("bundle");
    if (!enrollBundleIds.includes(bundleId)) {
      setEnrollBundleIds([...enrollBundleIds, bundleId]);
    } else {
      const updatedBundleIds = enrollBundleIds.filter((id) => id !== bundleId);
      setEnrollBundleIds(updatedBundleIds);

      // Enable both sections if no more bundles are selected
      if (updatedBundleIds.length === 0) {
        setSelectionType(null);
      }
    }
  };

  const countCoursesInBundles = () => {
    return bundleData.reduce((total, bundle) => {
      if (enrollBundleIds.includes(bundle.id)) {
        total += bundle.courses.length;
      }
      return total;
    }, 0);
  };

  const handleTopUpClick = async () => {
    try {
      let response;

      if (selectionType === "bundle") {
        const enrollData = {
          bundles: enrollBundleIds.map((id) => id),
          trainees: trainees.map((id) => id),
        };
        response = await enrollCourseBundle(header, enrollData); // Call bundle API
      } else if (selectionType === "course") {
        const enrollData = {
          courses: enrollCourseIds.map((id) => id),
          trainees: trainees.map((id) => id),
        };
        response = await enrollCourse(header, enrollData); // Call course API
      }

      if (response && response.data.isSuccess) {
        toast.success("Enrollment successful");
      } else {
        const errorMessage =
          response && response.data.message
            ? response.data.message
            : "Enrollment failed";
        throw new Error(errorMessage);
      }

      if (!isSubscription) {
        fetchCreditDetails();
      }

      setIsOpen(false);
      navigate("/learning/admin/trainees");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unknown error occurred.";
      toast.error("Error enrolling courses: " + errorMessage);
    }
  };

  const handleEnrollButton = () => {
    setIsOpen(true);
  };

  const handlePopupCancel = () => {
    setIsOpen(false);
  };

  const handleViewBundle = (bundle) => {
    setSelectedBundle(bundle);
  };

  const isDisabled = (section) => {
    if (section === "courses" && selectionType === "bundle") return true;
    if (section === "bundles" && selectionType === "course") return true;
    return false;
  };

  const isEnrollButtonDisabled =
    enrollCourseIds.length === 0 && enrollBundleIds.length === 0;

  // for courses
  const handleCourseSearch = (searchText) => {
    setCourseSearchKey(searchText);
    return [];
  };
  const handleClearCourseSearch = () => {
    setCourseSearchKey("");
    // setKey(!key);
  };
  const handleCourseValueClick = (value) => {
    setCourseSearchKey(value);
  };

  // for bundle courses
  const handleBundleSearch = (searchText) => {
    setBundleSearchKey(searchText);
    return [];
  };
  const handleClearBundleSearch = () => {
    setBundleSearchKey("");
    // setKey(!key);
  };
  const handleBundleValueClick = (value) => {
    setBundleSearchKey(value);
  };

  return (
    <div className="admin-enroll-courses-body">
      <div className="admin-enroll-courses-body_head">
        <div
          className="admin-enroll-courses-body_head-icon"
          onClick={() => navigate("/learning/admin/trainees")}
        >
          <FaArrowLeft />
        </div>
        <div className="admin-enroll-courses-body_head-title">
          Enroll Courses
        </div>
      </div>
      <div className="admin-enroll-courses-body_container">
        <div className="admin-enroll-courses-body_container-head">
          <p>
            Select one or more{" "}
            {selectionType === "bundle" ? "bundles" : "courses"} for user
          </p>
          <button
            className={`admin-enroll-courses-body_container-head-button ${
              !isEnrollButtonDisabled ? "active" : ""
            }`}
            disabled={isEnrollButtonDisabled}
            onClick={handleEnrollButton}
          >
            {enrollCourseIds.length
              ? "Enroll Course"
              : enrollBundleIds.length
              ? "Enroll Bundle"
              : "Enroll"}
          </button>
        </div>

        {/* Bundle Courses Section */}
        <div
          className={`bundle-courses-section ${
            isDisabled("bundles") ? "disabled-section" : ""
          }`}
          style={{ opacity: isDisabled("bundles") ? 0.5 : 1 }}
          onClick={() => {
            if (isDisabled("bundles")) {
              toast.error(
                "You cannot select bundles while courses are selected"
              );
            }
          }}
        >
          <div className="bundle-course-head">
            <div className="bundle-course-head-title">Bundle Courses</div>
            <div className="bundle-course-head-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleBundleSearch}
                onValueClick={handleBundleValueClick}
                onClearSearch={handleClearBundleSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          <div className="bundle-course-items">
            {isBundleLoading ? (
              <AppLoader style={{ height: "380px" }} />
            ) : bundleCount > 0 ? (
              <GridLayout rows={5}>
                {bundleData.map((bundle, index) => (
                  <div
                    key={index}
                    className={`bundle-course-item ${
                      enrollBundleIds.includes(bundle.id) ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor: enrollBundleIds.includes(bundle.id)
                        ? "#7a83ba"
                        : "",
                      color: enrollBundleIds.includes(bundle.id) ? "white" : "",
                    }}
                    onClick={() =>
                      !isDisabled("bundles") && handleBundleSelect(bundle.id)
                    }
                  >
                    <div className="bundle-course-image">
                      <div className="bundle-icon-stack">
                        {bundle.courses.slice(0, 4).map((course, index) => (
                          <img
                            key={course.id}
                            src={course.icon}
                            className={`stacked-icon stacked-icon-${index}`}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="bundle-course-item-name">
                      {bundle.name}{" "}
                    </div>
                    <div className="bundle-course-item-credit_info">
                      1 individual course credit per trainee{" "}
                    </div>
                    <div className="bundle-course-item-bottom">
                      <div className="bundle-course-item-bottom-course_info">
                        <div className="bundle-course-item-bottom-course_info-key">
                          Total :
                        </div>
                        <div className="bundle-course-item-bottom-course_info-value">
                          {bundle.course_count} Courses
                        </div>
                      </div>
                      <div
                        className="bundle-course-item-bottom-view"
                        onClick={() => handleViewBundle(bundle)}
                      >
                        View Courses
                      </div>
                    </div>
                  </div>
                ))}
              </GridLayout>
            ) : (
              <EmptyContainer message="No bundles found!!" />
            )}
          </div>

          {bundleCount > 0 && (
            <div className="bundle-course-bottom">
              <div className="bundle-course-bottom-pagination">
                <AppPagination
                  setCurrentPage={setBundlePageNo}
                  currentPage={bundlePageNo}
                  recordsPerPage={bundleRecordsPerPage}
                  data_count={bundleCount}
                />
              </div>
              <div className="bundle-course-bottom-dropdown">
                <div className="bundle-course-bottom-dropdown-text">
                  Items to display
                </div>
                <div className="bundle-course-bottom-dropdown-button">
                  <DropdownButton
                    text="5 Items"
                    items={bundleLimits}
                    customStyle={customDropdownStyle}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* All Courses Section */}
        <div
          className={`admin-enroll-courses-body_container-body-content ${
            isDisabled("courses") ? "disabled-section" : ""
          }`}
          style={{ opacity: isDisabled("courses") ? 0.5 : 1 }}
        >
          <div className="admin-enroll-courses-body_container-body-content-head">
            <div className="admin-enroll-courses-body_container-body-content-head-title">
              All Courses
            </div>
            <div className="admin-enroll-courses-body_container-body-content-head-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleCourseSearch}
                onValueClick={handleCourseValueClick}
                onClearSearch={handleClearCourseSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          <div className="admin-enroll-courses-body_container-body-content-item">
            {isCourseLoading ? (
              <AppLoader style={{ height: "380px" }} />
            ) : courseData.length > 0 ? (
              <GridLayout rows={5}>
                {courseData.map((course, index) => (
                  <div
                    className={
                      "admin-enroll-courses-body_container-body-content-item-details-courses " +
                      (enrollCourseIds.includes(course.id) ? "selected" : "")
                    }
                    key={course.id}
                    onClick={() => handleCourseEnrollToggle(course.id, index)}
                  >
                    <div className="admin-enroll-courses-body_container-body-content-item-details-courses-img">
                      <img src={course.icon} alt="admin-course"></img>
                    </div>
                    <div className="admin-enroll-courses-body_container-body-content-item-details-courses-text">
                      <h3>{course.name}</h3>
                      <p className="admin-enroll-courses-body_container-body-content-item-details-courses-text-module">
                        {course.module_count} Modules
                      </p>
                      <p>1 individual course credit per trainee</p>
                    </div>
                  </div>
                ))}
              </GridLayout>
            ) : (
              <EmptyContainer message="No courses found!!" /> // Show empty container
            )}
          </div>
          {courseCount > 0 && (
            <div className="admin-enroll-courses-body_container-body-content-bottom">
              <div className="admin-enroll-courses-body_container-body-content-bottom-pagination">
                <AppPagination
                  setCurrentPage={setCoursePageNo}
                  currentPage={coursePageNo}
                  recordsPerPage={courseRecordsPerPage}
                  data_count={courseCount}
                />
              </div>
              <div className="admin-enroll-courses-body_container-body-content-bottom-dropdown">
                <div className="admin-enroll-courses-body_container-body-content-bottom-dropdown-text">
                  Items to display
                </div>
                <div className="admin-enroll-courses-body_container-body-content-bottom-dropdown-button">
                  <DropdownButton
                    text="6 Items"
                    items={courseLimits}
                    customStyle={customDropdownStyle}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <PopupModal setIsOpen={setIsOpen}>
          <div className="admin-enroll-courses_modal">
            <h2 className="admin-enroll-courses_modal-title">Enroll Courses</h2>
            <div className="admin-enroll-courses_modal-cards">
              <div className="admin-enroll-courses_modal-cards-trainees">
                <h5>No. of Trainees</h5>
                <h1>{trainees.length}</h1>
              </div>
              {selectionType === "course" ? (
                <div className="admin-enroll-courses_modal-cards-courses">
                  <h5>No. of Courses</h5>
                  <h1>{enrollCourseIds.length}</h1>
                </div>
              ) : (
                <div className="admin-enroll-courses_modal-cards-courses">
                  <h5>No. of Courses in Selected Bundles</h5>
                  <h1>{countCoursesInBundles()}</h1>
                </div>
              )}
            </div>
            <div className="admin-enroll-courses_modal-buttons">
              <button
                className="admin-enroll-courses_modal-buttons-button"
                onClick={handlePopupCancel}
              >
                Cancel
              </button>
              {isCreditNeeded ? (
                <button
                  id="topup-button"
                  className="admin-enroll-courses_modal-buttons-button"
                  onClick={() => navigate("/learning/admin/credits")}
                >
                  TopUp
                </button>
              ) : (
                <button
                  id="topup-button"
                  className="admin-enroll-courses_modal-buttons-button"
                  onClick={handleTopUpClick}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </PopupModal>
      )}

      {/* Popup for Bundle Courses */}
      {selectedBundle && (
        <PopupModal setIsOpen={() => setSelectedBundle(null)}>
          <div className="bundle-popup">
            <h2>{selectedBundle.name} Courses</h2>
            <div className="bundle-course-list">
              {selectedBundle.courses.map((course, index) => (
                <div key={index} className="bundle-course-list-item">
                  <img
                    src={course.icon}
                    alt="course"
                    className="course-thumbnail"
                  />
                  <div className="bundle-course-info">
                    <h4>{course.name}</h4>
                    <p>{course.module_count} Modules</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminEnrollCourses;
