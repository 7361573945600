import React, { useState, useEffect } from "react";
import "./Organisation.scss";
import { FaEdit, FaCamera } from "react-icons/fa";
import user_avatar from "../../learning/images/icons/user_avatar.jpg";
import AppLoader from "../../learning/user/components/AppLoader";
import {
  updateOrganisation,
  getOrganisationData,
} from "../services/api.services";
import toast from "react-hot-toast";
import EditProfilePicturePopup from "./EditProfilePicturePopup";

const Organisation = ({ header }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [organisationData, setOrganisationData] = useState(null);
  const [key, setKey] = useState(1);

  const fetchOrganisationData = async () => {
    setIsLoading(true);
    try {
      const response = await getOrganisationData(header);
      setOrganisationData(response.data.results[0]);
      setFormData({
        name: response.data.results[0].name || "",
        admin_email: response.data.results[0].admin_email || "",
        contact: response.data.results[0].contact || "",
        address: response.data.results[0].address || "",
      });
    } catch (error) {
      toast.error("Failed to fetch organisation data.");
      console.error("Error fetching organisation data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganisationData();
  }, [key]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    const updatedFields = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== organisationData[key]) {
        updatedFields.append(key, formData[key]);
      }
    });

    try {
      const response = await updateOrganisation(
        header,
        organisationData.id,
        updatedFields
      );
      if (response.status === 200) {
        toast.success("Organisation updated successfully.");
        setIsEditMode(false);
        fetchOrganisationData(); // Refresh data after successful update
      } else {
        toast.error("Failed to update organisation.");
        console.error(response.message);
      }
    } catch (error) {
      toast.error("Error updating organisation.");
      console.error("Error updating organisation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setFormData({
      name: organisationData.name,
      admin_email: organisationData.admin_email,
      contact: organisationData.contact,
      address: organisationData.address,
    });
  };

  if (isLoading)
    return (
      <div className="organisation-info">
        <AppLoader style={{ height: "300px" }} />
      </div>
    );

  return (
    <div className="organisation-info">
      <div className="organisation-info-title">
        Organisation Info{" "}
        <FaEdit
          onClick={() => setIsEditMode(true)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="organisation-info-content">
        <div className="organisation-info-content-profile">
          <div className="organisation-info-content-profile-image">
            <EditProfilePicturePopup
              defaultAvatar={organisationData.logo}
              defaultText={organisationData.short_name}
              userAvatar={organisationData.logo}
              updateFunction={updateOrganisation}
              header={header}
              instanceId={organisationData.id}
              key={key}
              setKey={setKey}
              formDataKey = "logo"
            />
            {organisationData.logo ? (
              <img src={organisationData.logo} alt="logo" />
            ) : (
              <div className="organisation-info-content-profile-image-text">
                {organisationData.short_name}
              </div>
            )}
          </div>
        </div>

        <div className="organisation-info-content-info">
          <div className="organisation-info-content-info-item">
            <div className="organisation-info-content-info-item-label">
              Organisation Name
            </div>
            <div className="organisation-info-content-info-item-input">
              {isEditMode ? (
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              ) : (
                organisationData.name || "Not Provided"
              )}
            </div>
          </div>
          <div className="organisation-info-content-info-item">
            <div className="organisation-info-content-info-item-label">
              Email
            </div>
            <div className="organisation-info-content-info-item-input">
              {isEditMode ? (
                <input
                  type="email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleInputChange}
                />
              ) : (
                organisationData.admin_email || "Not Provided"
              )}
            </div>
          </div>
          <div className="organisation-info-content-info-item">
            <div className="organisation-info-content-info-item-label">
              Contact
            </div>
            <div className="organisation-info-content-info-item-input">
              {isEditMode ? (
                <input
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                />
              ) : (
                organisationData.contact || "Not Provided"
              )}
            </div>
          </div>
          <div
            className="organisation-info-content-info-item"
            style={{ width: "100%" }}
          >
            <div className="organisation-info-content-info-item-label">
              Address (Optional)
            </div>
            <div className="organisation-info-content-info-item-input">
              {isEditMode ? (
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              ) : (
                organisationData.address || "Not Provided"
              )}
            </div>
          </div>
        </div>
      </div>

      {isEditMode && (
        <div className="organisation-info-bottom">
          <button className="secondary-button" onClick={handleCancel}>
            Cancel
          </button>
          <button className="primary-button" onClick={handleSave}>
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default Organisation;
