import React, { useState, useEffect, useContext } from "react";
import "./AdminCourse.scss";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { getAdminCourseData } from "../services/api.services";
import AppStartCourse from "../../../learning/user/components/AppStartCourse";
import AppPagination from "../components/AppPagination";
import SearchBox from "../../user/components/SearchBox";

const AdminCourse = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [courseDetail, setCourseDetails] = useState([]);
  const [count, setCount] = useState(0);
  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    getCourseData();
  }, [searchKey, pageNo]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getCourseData = async () => {
    try {
      const response = await getAdminCourseData(
        header,
        pageNo,
        recordsPerPage,
        searchKey
      );
      if (response.status === 200) {
        setCount(response.data.count);
        setCourseDetails(response.data.results);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
    return [];
  };
  const handleClearSearch = () => {
    setSearchKey("");
  };
  const handleValueClick = (value) => {
    setSearchKey(value);
  };

  return (
    <div className="admin-course">
      <div className="admin-course-title">
        <div className="admin-course-title-text">All Courses</div>
        <div className="admin-course-title-search-box">
          <SearchBox
            searchData={[]}
            onSearch={handleSearch}
            onValueClick={handleValueClick}
            onClearSearch={handleClearSearch}
            useDynamicSearch={true}
          />
        </div>
      </div>
      <div className="admin-course-list">
        <AppStartCourse
          selectedCourses={courseDetail}
          isStartButton={false}
          header={header}
          setStatus={null}
          status={null}
          courseCount={count}
          setPageNo={setPageNo}
        />
        {count > recordsPerPage && !searchKey && (
          <AppPagination
            setCurrentPage={setPageNo}
            currentPage={pageNo}
            recordsPerPage={recordsPerPage}
            data_count={count}
          />
        )}
      </div>
    </div>
  );
};

export default AdminCourse;
