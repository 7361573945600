import React, { useState, useEffect } from 'react';
import './AdminCustomCheckbox.scss';

const AdminCustomCheckbox = ({ selectAll, data_id, setCheckedData, checkedData, responseData }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectAll) {
      setChecked(checkedData.length === responseData.length);
    } else {
      setChecked(checkedData.includes(data_id));
    }
  }, [selectAll, checkedData, data_id, responseData]);

  const handleSingleBoxCheck = (data) => {
    if (!checkedData.includes(data)) {
      setCheckedData([...checkedData, data]);
    } else {
      setCheckedData(checkedData.filter((id) => id !== data));
    }
  };

  const handleAllBoxCheck = () => {
    if (checkedData.length === responseData.length) {
      setCheckedData([]);
    } else {
      setCheckedData(responseData.map((response) => response.id));
    }
  };

  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={selectAll ? handleAllBoxCheck : () => handleSingleBoxCheck(data_id)}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default AdminCustomCheckbox;
