import React, { useState, useEffect } from "react";
import "./PersonalInfo.scss";
import { FaEdit } from "react-icons/fa";
import EditProfilePicturePopup from "./EditProfilePicturePopup";
import AppLoader from "../../learning/user/components/AppLoader";
import toast from "react-hot-toast";
import { getUserInfo, updateUserDetails } from "../services/api.services";

const PersonalInfo = ({ header }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState(1);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserInfo(header);
        setUserDetails(response.data.results[0]);
        setIsLoading(false);
      } catch (error) {
        toast.error("Failed to load user details");
        setIsLoading(false);
      }
    };
    fetchUserDetails();
  }, [key]);

  const handleInputChange = (key, value) => {
    setUpdatedDetails({ ...updatedDetails, [key]: value });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const response = await updateUserDetails(
        header,
        userDetails.id,
        updatedDetails
      );
      if (response.status === 200) {
        toast.success("User details updated successfully");
        setUserDetails(response.data);
        setEditMode(false);
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      toast.error("Failed to update user details");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader style={{ height: "300px" }} />;
  }

  return (
    <div className="personal-info">
      <div className="personal-info-title">
        Personal Info <FaEdit onClick={() => setEditMode(!editMode)} />
      </div>
      <div className="personal-info-content">
        <div className="personal-info-content-profile">
          <div className="personal-info-content-profile-image">
            <EditProfilePicturePopup
              defaultAvatar={userDetails.avatar}
              defaultText={userDetails.short_name}
              userAvatar={userDetails.avatar}
              updateFunction={updateUserDetails}
              header={header}
              instanceId={userDetails.id}
              key={key}
              setKey={setKey}
              formDataKey = "avatar"
            />
            {userDetails.avatar ? (
              <img src={userDetails.avatar} alt="image" />
            ) : (
              <div className="personal-info-content-profile-image-text">
                {userDetails.short_name}
              </div>
            )}
          </div>
        </div>

        <div className="personal-info-content-info">
          {[
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Email", key: "email" },
            { label: "Contact No.", key: "mobile_number" },
            { label: "Jobrole / Department", key: "jobrole" },
          ].map(({ label, key }) => (
            <div key={key} className="personal-info-content-info-item">
              <div className="personal-info-content-info-item-label">
                {label}
              </div>
              <div className="personal-info-content-info-item-input">
                {editMode ? (
                  <input
                    type="text"
                    defaultValue={userDetails[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                ) : (
                  userDetails[key]
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {editMode && (
        <div className="personal-info-bottom">
          <button
            className="secondary-button"
            onClick={() => setEditMode(false)}
          >
            Cancel
          </button>
          <button className="primary-button" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default PersonalInfo;
