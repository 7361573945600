import React, { useState } from "react";
import { FaTimes, FaBan } from "react-icons/fa";
import toast from "react-hot-toast";
import "./BlockTraineePopup.scss";
import { setTraineeData } from "../services/api.services";

const BlockTraineePopup = ({ idArray, header, onSuccess, isActive }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirm = async () => {
    try {
      const response = await setTraineeData(header, idArray, "False");
      if (response.status === 200 && response.data.isSuccess) {
        toast.success(`Users blocked successfully!!`);
        onSuccess();
        toggleModal();
      } else {
        toast.error(`Failed to block user`);
      }
    } catch (error) {
      toast.error(`Error blocking user: ${error.message}`);
    }
  };

  return (
    <div>
      <div
        className={`block-actions-button ${!isActive ? "disabled" : ""}`}
        onClick={isActive ? toggleModal : null}
      >
        <div className="block-actions-button-icon">
          <FaBan />
        </div>
        <div className="block-actions-button-text">Inactive</div>
      </div>

      {isOpen && (
        <div className="block_trainee_popup-overlay">
          <div className="block_trainee_popup-content">
            <div
              onClick={toggleModal}
              className="block_trainee_popup-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="block_trainee_popup-content-text">
              Are you sure you want to block selected {idArray.length}{" "}
              {idArray.length === 1 ? "user" : "users"}?
            </div>

            <div className="block_trainee_popup-content-action">
              <button
                className="block_trainee_popup-content-action-confirm"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="block_trainee_popup-content-action-cancel"
                onClick={toggleModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlockTraineePopup;
